.slider_img_one {
  background: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/fashion.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slider_img_two {
  background: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/production.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slider_img_three {
  background: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/shipping.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slider_img_four {
  background: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  background-image: url("../../assets/images/slider/fashion-2.jpg");
  width: 100%;
  height: 90vh;
  background-position: center center;
  background-size: cover;
}
.slidercontent {
  margin-bottom: 220px;
  color: #fff;
}
.slidercontent h1 {
  padding-bottom: 15px;
  font-weight: 700;
}
.slidercontent p {
  font-size: 2rem;
}

@media (min-width: 360px) and (max-width: 768px) {
  .slider_img_one,
  .slider_img_two,
  .slider_img_three,
  .slider_img_four {
    height: 40vh;
    width: 100%;
  }
  .slidercontent {
    margin-bottom: 90px;
  }
  .slidercontent h1 {
    padding-bottom: 5px;
    font-size: 1.25rem;
  }
  .slidercontent p {
    font-size: 0.85rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .slider_img_one,
  .slider_img_two,
  .slider_img_three,
  .slider_img_four {
    height: 60vh;
    width: 100%;
  }
  .slidercontent {
    margin-bottom: 160px;
  }
  .slidercontent h1 {
    padding-bottom: 5px;
    font-size: 1.125rem;
  }
  .slidercontent p {
    font-size: 0.875em;
  }
}

.footer_section {
  background: #f7f7f7;
  color: #000000;
}
.footer_top {
  padding: 50px 0px;
}
.page_wrapper {
  width: 100%;
  height: 100%;
  background: rgb(143, 143, 143);
}
.footer_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: rgb(27, 27, 27);
  color: #fff;
}
.footer_bottom p {
  margin-top: 15px;
}

.footer_bottom a {
  flex-grow: 1;
  color: #0070f3;
  text-decoration: none;
  font-weight: bold;
}

@media (min-width: 360px) and (max-width: 768px) {
  .footer_section {
    padding: 25px 0px;
  }
  .footer_company_logo img {
    height: 100%;
    width: 60% !important;
    margin-bottom: 20px;
  }
  .footer_bottom {
    padding: 50px 5px;
  }
}
